import React from 'react';
import { Character, List, ListName, Ref } from './types.ts';
import { launchDownload } from './util.ts';
import PonyContainer from './PonyContainer.tsx';
import Lists from './Lists.ts';
import Tabs from './Tabs.tsx';
import TabPane from './TabPane.tsx';

function EndScreen(props: {
    smashes: React.MutableRefObject<Character[]>,
    list: React.MutableRefObject<Character[]>,
    listType: Ref<ListName>
}) {
    const list = Lists[props.listType.current] as List;
    const shameText = list.getShameText?.(props.smashes.current, props.list.current);
    const passes = props.list.current.filter(c => !props.smashes.current.includes(c));

    const download = () => {
        const name = window.prompt('Enter a name, if you want', '');
        const json = JSON.stringify({name, smash: props.smashes.current, pass: passes});
        launchDownload(json, 'ponysmash.json', 'application/json');
    };

    return (
        <>
            <p className='pony-name'>Its over!, You would smash {props.smashes.current.length} out of {props.list.current.length} characters.</p>
            {
                (() => {
                    switch (props.listType.current) {
                        case 'custom': return null;
                        default: {
                            return (<>
                                {
                                    shameText !== '' && typeof shameText != 'undefined' && shameText != null ? (<>
                                        <p className='pony-name'>{shameText}</p>
                                        <br /></>) : null}
                                <p>If you want to add a character or contribute, read the README on GitHub (its at the bottom of the page)</p>
                            </>);
                        }
                    }
                })()
            }
            <p>You can <a onClick={download}>download your results</a> as JSON.</p>
            <Tabs>
                <TabPane title='Smash'>
                    <PonyContainer smashes={props.smashes.current} />
                </TabPane>
                <TabPane title='Pass'>
                    <PonyContainer smashes={passes} />
                </TabPane>
            </Tabs>
        </>
    );
}

export default EndScreen;
