import React, { ReactElement, useState } from 'react';
import { Props as TabPaneProps } from './TabPane';
import './css/Tabs.css';

type Props = {
  children: ReactElement<TabPaneProps>[];
};

const Tabs = (props: Props): JSX.Element => {
  const { children } = props;

  // First tab is shown by default
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <div>
      <ul className='tablist' role='tablist' aria-orientation='horizontal'>
        {children.map((item, index) => (
            <li key={index} className={`tab-title ${index === selectedTabIndex ? 'active' : ''}`}>
                <button role='tab' aria-selected={index === selectedTabIndex} onClick={() => setSelectedTabIndex(index)}>{item.props.title}</button>
            </li>
        ))}
      </ul>
      {children[selectedTabIndex]}
    </div>
  );
};

export default Tabs;
