import React, { useRef, useState } from 'react';

import { Character, EventDecorations, GameState } from './types.ts';
import { Events } from './Events.tsx';
import PageInner from './PageInner.tsx';
import { Footer } from './Footer.tsx';


import './css/Events.css';
function App() {
    const eventDecoration = useRef<EventDecorations>('none');
    const [eventData, eventDataSet] = useState<object>({});
    const [gameState, setGameState] = useState<GameState>('menu');
    const smashes = useRef<Character[]>([]);

    const reset = () => {
        smashes.current.splice(0, Infinity);
        setGameState('menu');
    };

    return (
        <>
            <Events key='events' eventDecoration={eventDecoration} eventDataSet={eventDataSet} />
            <PageInner key='page-inner' smashes={smashes} gameState={gameState} setGameState={setGameState} />
            <Footer key='footer' event={eventDecoration} eventData={eventData} goToStats={() => setGameState('stats')} reset={reset} />
        </>
    );
}

export default App;
