import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import Lists from './Lists.ts';
import { Character } from './types';
import Tabs from './Tabs.tsx';
import PonyContainer from './PonyContainer.tsx';
import TabPane from './TabPane.tsx';
import './css/Stats.css';
import { launchDownload } from './util.ts';

// Enable custom properties
declare module 'react' {
    interface CSSProperties {
        [key: `--${string}`]: string | number
    }
}

interface PonyEnjoyer {
    name: string | null;
    wouldSmash: Set<string>;  // just names
}

interface Tier {
    grade: string;
    near: number;
    plusChar?: string;
    baseColour: string;
    nextColour: string;
}

interface AllocTier extends Tier {
    plus?: number;
    maxPlus: {value: number};
}

interface Error {
    url: string;
    message: string
};

const allCharacters: Character[] =
    [...Lists.default.list, ...Lists.apples.list, ...Lists.community.list]
    .filter((a,i,all) => all.findIndex((b) => b.name === a.name) === i)
    .sort((a, b) => a.name.localeCompare(b.name));

const sPlusTier: Tier = {grade: 'S+', near: 1, baseColour: 'hsl(340, 100%, 75%)', nextColour: ''};
const sTier: Tier = {grade: 'S', near: 0.9, baseColour: 'hsl(0, 100%, 75%)', nextColour: ''};
const fTier: Tier = {grade: 'F', near: 0, baseColour: 'rgb(207, 207, 207)', nextColour: ''}; // only granted for 0%
const middleTiers: Tier[] = [
    {grade: 'A', near: 0.755, baseColour: 'hsl(30, 100%, 75%)', nextColour: 'hsl(0, 100%, 75%)', plusChar: 'A'},
    {grade: 'B', near: 0.655, baseColour: 'hsl(45, 100%, 75%)', nextColour: 'hsl(10, 100%, 75%)'},
    {grade: 'C', near: 0.505, baseColour: 'hsl(60, 100%, 75%)', nextColour: 'hsl(45, 100%, 75%)'},
    {grade: 'D', near: 0.305, baseColour: 'hsl(90, 100%, 75%)', nextColour: 'hsl(60, 100%, 75%)'},
    {grade: 'E', near: 0,  baseColour: 'hsl(140, 100%, 75%)', nextColour: 'hsl(90, 100%, 75%)'},
];

async function fetchEnjoyer(url: string): Promise<PonyEnjoyer> {
    console.log(`Off to fetch ${url}`);
    if (!url.startsWith('https://')) {
        throw new Error("Doesn't start with https://");
    }
    const response = await fetch(url);
    const json = await response.json();
    return parseEnjoyer(json);
}

async function readEnjoyer(file: File): Promise<PonyEnjoyer> {
    const json = JSON.parse(await file.text());
    return parseEnjoyer(json);
}

function parseEnjoyer(json: any): PonyEnjoyer {
    if (json.name === undefined || !(json.name === null || typeof json.name === 'string')) {
        throw new Error('Bad structure or name value');
    }
    if (json.smash === undefined || !Array.isArray(json.smash)) {
        throw new Error('Bad structure or smash value');
    }
    return {name: json.name, wouldSmash: new Set((json.smash as Array<Character>).map(character => character.name))};
}

function groupByWouldSmash<A extends {totalWouldSmash: number}>(sortedArray: A[]): A[][] {
    if (!sortedArray.length) {
        return [];
    }
    let last = sortedArray[0].totalWouldSmash;
    let stride: A[] = [];
    const result: A[][] = [];
    sortedArray.forEach(a => {
        if (a.totalWouldSmash === last) {
            stride.push(a);
        } else {
            result.push(stride);
            stride = [a];
            last = a.totalWouldSmash;
        }
    });
    result.push(stride);
    return result;
}

function tierBg(tier: AllocTier): CSSProperties {
    return {
        '--base-colour': tier.baseColour,
        ...(tier.plus ? {'--colour-mix': `color-mix(in lch, ${tier.baseColour}, ${tier.nextColour} ${tier.plus / (tier.maxPlus.value + 1) * 100}%)`} : {}),
    };
}

const defaultFilters = {
    EQG: true,
    underage: true,
    females: true,
    males: true,
    community: true,
    'earth ponies': true,
    pegasi: true,
    unicorns: true,
    alicorns: true,
    kirin: true,
    changelings: true,
    'non-ponies': true,
}

function charFilter(filters: typeof defaultFilters): (c: Character) => boolean {
    return (c) => {
        return (filters.EQG || !c.eqg) &&
            (filters.underage || !c.filly) &&
            (filters.females || c.gender != 'female') &&
            (filters.males || c.gender != 'male') &&
            (filters.community || !c.community) &&
            (filters['earth ponies'] || c.tribe !== 'earth') &&
            (filters.pegasi || c.tribe !== 'pegasus') &&
            (filters.unicorns || c.tribe !== 'unicorn') &&
            (filters.alicorns || c.tribe !== 'alicorn') &&
            (filters.kirin || c.tribe !== 'kirin') &&
            (filters.changelings || c.tribe !== 'changeling') &&
            (filters['non-ponies'] || !!c.tribe);
    };
}

function ResultsView(props: {
    tieredHighToLow: {
        percent: number,
        rank: number,
        smashes: number,
        tier: AllocTier,
        tierStr: string,
        characters: (Character & {wouldSmash: string})[],
    }[],
    enjoyerCount: number,
}): JSX.Element {
    const { enjoyerCount } = props;
    const [filters, setFilters] = useState({...defaultFilters});
    const tieredHighToLow = props.tieredHighToLow.map(tier =>
        ({...tier, characters: tier.characters.filter(charFilter(filters))}));
    const [smashes, passes] =
        tieredHighToLow.at(-1)!.percent === 0
            ? [tieredHighToLow.slice(0, -1).flatMap(t => t.characters), tieredHighToLow.at(-1)!.characters]
            : [tieredHighToLow.flatMap(t => t.characters), []];
    const download = () => {
        const csv = ('rank,smashes,percent,tier,name\r\n' +
            tieredHighToLow.flatMap(({rank, percent, smashes, tierStr, characters}) => characters.map(character =>
                `${rank},${smashes},${percent.toFixed(0)},"${tierStr}","${character.name.replace(/"/g, '')}"`))
            .join('\r\n'));
        launchDownload(csv, 'ponysmash.csv', 'text/csv');
    };
    return (
        <>
        <p>
            Show
            {Object.entries(filters).map(([key, value]) =>
                <span className='stats-filter' key={key}>
                    <input id={`filter-${key.replace(' ', '-')}`} type='checkbox' checked={value} onChange={(e) => setFilters({...filters, [key]: e.target.checked})} />
                    &nbsp;<label htmlFor={`filter-${key.replace(' ', '-')}`}>{key}</label>
                </span>
            )}
        </p>
        <p>And the results are: {enjoyerCount} ponybros would, between them, {passes.length === 0 ? 'smash everypony' : `leave ${passes.length} ponies untouched`}.</p>
        <p className='no-print'><a onClick={download}>Download results as CSV</a></p>
        <Tabs>
            <TabPane title='Ranking'>
                <table className='stats-table'>
                    <thead>
                        <tr><th>#</th><th>Votes</th><th>(%)</th><th>Name</th></tr>
                    </thead>
                    <tbody>
                        {tieredHighToLow.flatMap(({rank, smashes, percent, characters}) => characters.map(character =>
                            <tr key={character.name}>
                                <td>{rank}</td>
                                <td title={character.wouldSmash}>{smashes}</td>
                                <td title={character.wouldSmash}>{percent.toFixed(0)}%</td>
                                <td>{character.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </TabPane>
            <TabPane title='Tiers'>
                <table className='stats-tiers'>
                    {tieredHighToLow.map(({percent, tier, tierStr, characters}) =>
                        <tr key={tierStr}>
                            <td style={tierBg(tier)}>
                                <p className='stats-tier'>{tierStr}</p>
                                <p>{percent.toFixed(0)}%</p>
                            </td>
                            <td>
                                {characters.map(c =>
                                    <img className='stats-img' key={c.name} src={c.img} alt={c.name} title={`${c.name} (${c.wouldSmash})`} />)
                                }
                            </td>
                        </tr>
                    )}
                </table>
            </TabPane>
            <TabPane title='Smash'>
                <PonyContainer smashes={smashes} />
            </TabPane>
            <TabPane title='Pass'>
                <PonyContainer smashes={passes} />
            </TabPane>
        </Tabs>
        </>
    );
}

function Results(props: {
    enjoyers: PonyEnjoyer[]
}): JSX.Element {
    const { enjoyers } = props;
    const ordered = allCharacters.map(character => {
        const smashing: (string | null)[] = [];
        enjoyers.forEach(enjoyer => {
            if (enjoyer.wouldSmash.has(character.name)) {
                smashing.push(enjoyer.name);
            }
        })
        const namedWouldSmash = smashing.filter(e => e);
        const unnamedWouldSmash = smashing.length - namedWouldSmash.length;
        const wouldSmash = namedWouldSmash.join(', ') + (unnamedWouldSmash > 0 ? ` and ${unnamedWouldSmash} others` : '');
        return {...character, wouldSmash, totalWouldSmash: smashing.length};
    }).sort((a, b) => b.totalWouldSmash - a.totalWouldSmash);
    const grouped = groupByWouldSmash(ordered);
    // Need to track max plus for colour interpolation
    const toAlloc = middleTiers.map(tier => ({...tier, target: tier.near * enjoyers.length}));
    let lastTier = {...middleTiers.at(-1)!, maxPlus: {value: -1}};
    let seen = 0;
    // Tiers are allocated low to high
    const tieredHighToLow = grouped.reverse().map((characters, index, array) => {
        const smashes = characters[0].totalWouldSmash;
        const percent = smashes / enjoyers.length * 100;
        const rank = ordered.length - seen - characters.length + 1;
        seen += characters.length;
        const tier: AllocTier = (() => {
            if (percent === 100) {
                return {...sPlusTier, maxPlus: {value: 0}};
            } else if (percent >= 90 && index === (grouped.length - (grouped.at(-1)![0].totalWouldSmash === enjoyers.length ? 2 : 1))) {
                return {...sTier, maxPlus: {value: 0}};
            } else if (percent === 0) {
                return {...fTier, maxPlus: {value: 0}};
            } else {
                const closest = toAlloc.sort((a, b) => Math.abs(a.target - smashes) - Math.abs(b.target - smashes))[0];
                if (closest.grade === lastTier.grade ||
                        Math.abs(closest.target - smashes) >= Math.abs(closest.target - (array.at(index + 1)?.at(0)?.totalWouldSmash || 0))) {
                    lastTier.maxPlus.value += 1;
                    return {...lastTier, plus: lastTier.maxPlus.value};
                } else {
                    lastTier = {...closest, maxPlus: {value: 0}};
                    return lastTier;
                }
            }
        })();
        const tierStr = `${tier.grade}${(tier.plusChar || '+').repeat(tier.plus || 0)}`;
        return {percent, smashes, rank, tier, tierStr, characters};
    }).reverse();
    return <ResultsView tieredHighToLow={tieredHighToLow} enjoyerCount={enjoyers.length} />;
}

function Stats(): JSX.Element {
    const [urlsText, setUrlsText] = useState('');
    const [files, setFiles] = useState<File[]>([]);
    const outstanding = useRef(new Set<string>());
    const cached = useRef(new Map<string, PonyEnjoyer>());
    const [errors, setErrors] = useState<Error[]>([]);
    const [enjoyers, setEnjoyers] = useState<PonyEnjoyer[]>([]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            const inputUrls = urlsText.split(/\s+/).filter(e => e);
            const collected: PonyEnjoyer[] = [];
            inputUrls.forEach(url => {
                const cachedResult = cached.current.get(url);
                if (cachedResult) {
                    collected.push(cachedResult);
                } else if (outstanding.current.has(url) || errors.some(error => error.url === url)) {
                } else {
                    fetchEnjoyer(url)
                        .then((enjoyer) => {
                            if (outstanding.current.has(url)) {
                                cached.current.set(url, enjoyer);
                                setEnjoyers(enjoyers => [...enjoyers, enjoyer])
                            }
                        })
                        .catch((error) => setErrors(errors => [...errors, {url, message: error?.message || `${error}`}]))
                        .finally(() => outstanding.current.delete(url));
                    outstanding.current.add(url);
                }
            });
            files.forEach(file => {
                readEnjoyer(file)
                    .then((enjoyer) => setEnjoyers(enjoyers => [...enjoyers, enjoyer]))
                    .catch((error) => setErrors(errors => [...errors, {url: file.name, message: error?.message || `${error}`}]));
                inputUrls.push(file.name);
            });
            setErrors(errors => errors.filter(error => inputUrls.includes(error.url)));
            setEnjoyers(collected);
        }, 500);
        return () => { clearTimeout(timeout) };
    }, [urlsText, files]);

    return (
        <div className='stats'>
            <div className='stats-input no-print'>
                <textarea className='stats-urls' value={urlsText} onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => setUrlsText(e.target.value)} rows={5} cols={33} placeholder='Whitespace-separated urls of JSON files here'/>
                <div>
                    <p>Upload local files:</p>
                    <input type='file' accept='.json,application/json' multiple onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFiles(Array.from(e.target.files || []))} />
                </div>
            </div>
            <ol>
                {errors.map(error => <li key={error.url}>Error reading <em>{error.url}</em>: {error.message}</li>)}
            </ol>
            {enjoyers.length ? <Results enjoyers={enjoyers} /> : null}
        </div>
    );
};

export default Stats;
