import React, { ReactElement } from 'react';

export type Props = {
  title: string;
  children: ReactElement | ReactElement[];
};

const TabPane = ({ children }: Props): JSX.Element => <div role='tabpanel'>{children}</div>;

export default TabPane;
