import React, { MutableRefObject, useRef } from 'react';
import EndScreen from './EndScreen.tsx';
import Game from './Game.tsx';
import Menu from './Menu.tsx';
import Stats from './Stats.tsx';
import { Character, GameState, ListName, StateSet } from './types.ts';
import './css/PageInner.css';

function PageInner(props: {
    gameState: GameState,
    setGameState: StateSet<GameState>,
    smashes: MutableRefObject<Character[]>,
}) {
    const { gameState, setGameState } = props;

    // Lists
    // const [listType, setType] = useState<ListName>('default');
    const listType = useRef<ListName>('default');
    const list = useRef<Character[]>([]);

    const ListsProps = {
        listType: listType,
        list
    };

    return (
        <div key='page-inner' className="page-inner">
            {
                (() => {
                    switch (gameState) {
                        case 'menu': return (<Menu key='menu' setGameState={setGameState} ListProps={ListsProps} />);
                        case 'ingame': return (<Game key='game' list={list} setGameState={setGameState} smashes={props.smashes} listName={listType.current} />);
                        case 'end': return (<EndScreen key='endscreen' smashes={props.smashes} list={list} listType={listType} />);
                        case 'stats': return (<Stats key='stats' />);
                    }
                })()
            }
        </div>
    );
}

export default PageInner;
